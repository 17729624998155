<template>
    <v-data-table
        :headers="headers"
        :items="confirmCustomers.lstCustomers"
        no-data-text="Nenhum cliente pendente"
        :footer-props="{ itemsPerPageText: 'Linhas por página' }"
        locale="pt-BR"
        :loading="confirmCustomers.loading"
        loading-text="Buscando clientes..."
        disable-sort
    >
        <template v-slot:item="{ item: cf1 }">
            <tr>
                <td class="text-left caption">{{ cf1.cf1cliente | cpfCnpj }}</td>
                <td class="text-left caption">{{ cf1.cf1nome | capitalize}}</td>
                <td class="text-left caption">{{ cf1.cf1abert | shortDate }}</td>
                <td class="text-left caption">{{ cf1.cf1fone }}</td>
                <td class="text-left caption text-lowercase">{{ cf1.cf1email }}</td>
                <td class="text-left caption">{{ cf1.cf1confone }}</td>
                <td>
                    <v-icon small @click="showCustomer(cf1)">
                        mdi-open-in-new
                    </v-icon>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: () => ({
        headers: [
            { text: 'CPF/CNPJ', value: 'cf1cliente' },
            { text: 'Razão social', value: 'cf1nome' },
            { text: 'Cadastro', value: 'cf1abert' },
            { text: 'Telefone', value: 'cf1fone' },
            { text: 'E-mail', value: 'cf1email' },
            { text: 'Celular', value: 'cf1confone' },
            { text: '', value: '' },
        ],
    }),
    computed: {
        parameters: {
            get() {
                return this.$store.state.parameters;
            },
        },
        confirmCustomers: {
            get() {
                return this.$store.state.confirmCustomers;
            },
        },
    },
    methods:{
        showCustomer(cf1){
            this.confirmCustomers.customer = cf1
            this.confirmCustomers.showDialog = true
        }
    }
};
</script>

<style>
</style>